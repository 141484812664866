body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.SceneContainer header a.no-style {
  color: inherit;
  text-decoration: none;
}

ul,ol {
  padding-left: 1rem;
  margin-top: 0;
  margin-bottom: 1rem;
}